import React from "react";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { cn } from "@coninsa-s2/utils";
import Icon from "@coninsa-s2/icon";

import ChevronDown from "./assets/chevron-down.inline.svg";
import ChevronUp from "./assets/chevron-up.inline.svg";

export const AccordionRoot = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Root>
>(({ className, ...props }, forwardedRef) => (
  <AccordionPrimitive.Root
    ref={forwardedRef}
    className={cn("w-full rounded-md ", className)}
    {...props}
  />
));

export const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, children, ...props }, forwardedRef) => (
  <AccordionPrimitive.Item
    ref={forwardedRef}
    className={cn("mt-4 shadow-md rounded-lg overflow-hidden", className)}
    {...props}
  >
    {children}
  </AccordionPrimitive.Item>
));

export const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, forwardedRef) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      className={cn(
        "group flex p-6 flex-1 cursor-pointer gap-1 items-center leading-none outline-none bg-white",
        "data-[state=open]:bg-white data-[state=open]:border-b border-s2-gray-200",
        className
      )}
      {...props}
      ref={forwardedRef}
    >
      {children}
      <ChevronDown
        className="ml-auto transition-transform duration-300 group-data-[state=open]:hidden"
        aria-hidden
      />
      <ChevronUp
        className="ml-auto hidden transition-transform duration-300 group-data-[state=open]:block"
        aria-hidden
      />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, forwardedRef) => (
  <AccordionPrimitive.Content
    className={cn(
      "data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden bg-white",
      className
    )}
    {...props}
    ref={forwardedRef}
  >
    <div className="px-5 py-4">{children}</div>
  </AccordionPrimitive.Content>
));

export {
  AccordionRoot as Root,
  AccordionItem as Item,
  AccordionTrigger as Trigger,
  AccordionContent as Content,
};
